<template>
  <q-card-section horizontal class="row justify-between q-mt-none">
    <q-card-section class="col-4 text-center">
      <a>Mengetahui,</a>
      <br />
      <a>Orang Tua / Wali</a>
      <br />
      <br />
      <br />..............................
    </q-card-section>
    <q-card-section class="col-4 text-center">
      <a>Padang, {{ siswa.tanggal | moment("DD MMMM YYYY") }}</a>
      <br />
      <a>Wali Kelas</a>
      <br />
      <br />
      <br />{{ siswa.wali_kelas }}
    </q-card-section>
  </q-card-section>
</template>

<script>
export default {
  props: ["siswa"]
};
</script>
