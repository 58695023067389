<template>
  <q-card-section>
    <p>
      <strong>Deskripsi Pengetahuan dan Keterampilan</strong>
    </p>
    <q-markup-table
      flat
      bordered
      wrap-cells
      dense
      separator="cell"
      style="width: 100%"
    >
      <thead>
        <tr>
          <th style="width:5%">
            <strong>No</strong>
          </th>
          <th style="width:35%">
            <strong>MATA PELAJARAN</strong>
          </th>
          <th style="width:10%">
            <strong>Aspek</strong>
          </th>
          <th style="width:50%">
            <strong>Deskripsi</strong>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td colspan="4">
            <strong>Kelompok A (Wajib)</strong>
          </td>
        </tr>

        <div
          v-for="el in kelompok.A"
          :key="el.id_mapel"
          style="display: contents"
        >
          <tr>
            <td rowspan="2">{{ el.number }}</td>
            <td rowspan="2" style="text-align:center;vertical-align:center">
              {{ el.nama_mapel }}
            </td>
            <td>Pengetahuan</td>
            <template>
              <q-td class="text-lebih-kecil deskripsi_content">
                {{ el.deskripsi_pengetahuan }}
                <q-popup-edit :value="nilai" title="Masukan Deskripsi">
                  <q-input
                    v-model="el.deskripsi_pengetahuan"
                    dense
                    autofocus
                    autogrow
                  />
                </q-popup-edit>
              </q-td>
            </template>
          </tr>
          <tr class="bg-grey-3">
            <td>Keterampilan</td>
            <template>
              <q-td class="text-lebih-kecil deskripsi_content">
                {{ el.deskripsi_keterampilan }}
                <q-popup-edit :value="nilai" title="Masukan Deskripsi">
                  <q-input
                    v-model="el.deskripsi_keterampilan"
                    dense
                    autofocus
                    autogrow
                  />
                </q-popup-edit>
              </q-td>
            </template>
          </tr>
        </div>

        <tr>
          <td colspan="4">
            <strong>Kelompok B (Wajib)</strong>
          </td>
        </tr>
        <div
          v-for="el in kelompok.B"
          :key="el.id_mapel"
          style="display: contents"
        >
          <tr>
            <td rowspan="2">{{ el.number }}</td>
            <td rowspan="2" style="text-align:center;vertical-align:center">
              {{ el.nama_mapel }}
            </td>
            <td>Pengetahuan</td>
            <template>
              <q-td class="text-lebih-kecil deskripsi_content">
                {{ el.deskripsi_pengetahuan }}
                <q-popup-edit :value="nilai" title="Masukan Deskripsi">
                  <q-input
                    v-model="el.deskripsi_pengetahuan"
                    dense
                    autofocus
                    autogrow
                  />
                </q-popup-edit>
              </q-td>
            </template>
          </tr>
          <tr class="bg-grey-3">
            <td>Keterampilan</td>
            <template>
              <q-td class="text-lebih-kecil deskripsi_content">
                {{ el.deskripsi_keterampilan }}
                <q-popup-edit :value="nilai" title="Masukan Deskripsi">
                  <q-input
                    v-model="el.deskripsi_keterampilan"
                    dense
                    autofocus
                    autogrow
                  />
                </q-popup-edit>
              </q-td>
            </template>
          </tr>
        </div>

        <tr>
          <td colspan="4">
            <strong>Kelompok C (Peminatan)</strong>
          </td>
        </tr>
        <div
          v-for="el in kelompok.C"
          :key="el.id_mapel"
          style="display: contents"
        >
          <tr>
            <td rowspan="2">{{ el.number }}</td>
            <td rowspan="2" style="text-align:center;vertical-align:center">
              {{ el.nama_mapel }}
            </td>
            <td>Pengetahuan</td>
            <template>
              <q-td class="text-lebih-kecil deskripsi_content">
                {{ el.deskripsi_pengetahuan }}
                <q-popup-edit :value="nilai" title="Masukan Deskripsi">
                  <q-input
                    v-model="el.deskripsi_pengetahuan"
                    dense
                    autofocus
                    autogrow
                  />
                </q-popup-edit>
              </q-td>
            </template>
          </tr>
          <tr class="bg-grey-3">
            <td>Keterampilan</td>
            <template>
              <q-td class="text-lebih-kecil deskripsi_content">
                {{ el.deskripsi_keterampilan }}
                <q-popup-edit :value="nilai" title="Masukan Deskripsi">
                  <q-input
                    v-model="el.deskripsi_keterampilan"
                    dense
                    autofocus
                    autogrow
                  />
                </q-popup-edit>
              </q-td>
            </template>
          </tr>
        </div>

        <tr>
          <td colspan="4">
            <strong>LINTAS MINAT</strong>
          </td>
        </tr>
        <div
          v-for="el in kelompok.C_LIMIT"
          :key="el.id_mapel"
          style="display: contents"
        >
          <tr>
            <td rowspan="2">{{ el.number }}</td>
            <td rowspan="2" style="text-align:center;vertical-align:center">
              {{ el.nama_mapel }}
            </td>
            <td>Pengetahuan</td>
            <template>
              <q-td class="text-lebih-kecil deskripsi_content">
                {{ el.deskripsi_pengetahuan }}
                <q-popup-edit :value="nilai" title="Masukan Deskripsi">
                  <q-input
                    v-model="el.deskripsi_pengetahuan"
                    dense
                    autofocus
                    autogrow
                  />
                </q-popup-edit>
              </q-td>
            </template>
          </tr>
          <tr class="bg-grey-3">
            <td>Keterampilan</td>
            <template>
              <q-td class="text-lebih-kecil deskripsi_content">
                {{ el.deskripsi_keterampilan }}
                <q-popup-edit :value="nilai" title="Masukan Deskripsi">
                  <q-input
                    v-model="el.deskripsi_keterampilan"
                    dense
                    autofocus
                    autogrow
                  />
                </q-popup-edit>
              </q-td>
            </template>
          </tr>
        </div>

        <tr>
          <td colspan="4">
            <strong>Kelompok D (Kekhasan)</strong>
          </td>
        </tr>
        <div
          v-for="el in kelompok.D"
          :key="el.id_mapel"
          style="display: contents"
        >
          <tr>
            <td rowspan="2">{{ el.number }}</td>
            <td rowspan="2" style="text-align:center;vertical-align:center">
              {{ el.nama_mapel }}
            </td>
            <td>Pengetahuan</td>
            <template>
              <q-td class="text-lebih-kecil deskripsi_content">
                {{ el.deskripsi_pengetahuan }}
                <q-popup-edit :value="nilai" title="Masukan Deskripsi">
                  <q-input
                    v-model="el.deskripsi_pengetahuan"
                    dense
                    autofocus
                    autogrow
                  />
                </q-popup-edit>
              </q-td>
            </template>
          </tr>
          <tr class="bg-grey-3">
            <td>Keterampilan</td>
            <template>
              <q-td class="text-lebih-kecil deskripsi_content">
                {{ el.deskripsi_keterampilan }}
                <q-popup-edit :value="nilai" title="Masukan Deskripsi">
                  <q-input
                    v-model="el.deskripsi_keterampilan"
                    dense
                    autofocus
                    autogrow
                  />
                </q-popup-edit>
              </q-td>
            </template>
          </tr>
        </div>
      </tbody>
    </q-markup-table>
  </q-card-section>
</template>
<script>
export default {
  props: ["siswa"],
  data() {
    return {
      nilai: 0,
      kelompok: {
        A: [],
        B: [],
        C: [],
        C_LIMIT: [],
        D: []
      }
    };
  },
  mounted() {},
  watch: {
    siswa: {
      deep: true,
      handler(val) {
        if (val.id) {
          this.prepareNilai();
        }
      }
    }
  },
  methods: {
    async simpan() {
      let temp = [];
      for (let item of this.kelompok.A) {
        temp.push(item);
      }
      for (let item of this.kelompok.B) {
        temp.push(item);
      }
      for (let item of this.kelompok.C) {
        temp.push(item);
      }
      for (let item of this.kelompok.C_LIMIT) {
        temp.push(item);
      }
      for (let item of this.kelompok.D) {
        temp.push(item);
      }
      await this.$http.put(
        `/akademik/riwayat_ekskul/simpan_detail/DESKRIPSI`,
        temp
      );
    },
    async prepareNilai() {
      let temp = {
        A: [],
        B: [],
        C: [],
        C_LIMIT: [],
        D: []
      };
      let resp = await this.$http.get(
        `/akademik/riwayat_raport/getNilai/${this.siswa.id}`
      );
      temp.A = resp.data.filter(x => {
        return x.tipe == "A";
      });
      temp.B = resp.data.filter(x => {
        return x.tipe == "B";
      });
      temp.C = resp.data.filter(x => {
        return x.tipe == "C";
      });
      temp.C_LIMIT = resp.data.filter(x => {
        return x.tipe == "C_LIMIT";
      });
      temp.D = resp.data.filter(x => {
        return x.tipe == "D";
      });
      let c = 0;

      for (let item of temp.A) {
        c++;
        item.number = c;
      }
      for (let item of temp.B) {
        c++;
        item.number = c;
      }
      for (let item of temp.C) {
        c++;
        item.number = c;
      }
      for (let item of temp.C_LIMIT) {
        c++;
        item.number = c;
      }
      for (let item of temp.D) {
        c++;
        item.number = c;
      }

      this.kelompok = temp;
    }
  }
};
</script>

<style lang="scss" scope>
.deskripsi_content {
  max-height: 70px !important;
}
</style>
