<template>
  <q-card-section>
    <q-markup-table
      class="q-ml-lg"
      flat
      bordered
      wrap-cells
      dense
      separator="cell"
      style="width: 100%"
    >
      <thead>
        <tr class="text-center">
          <th rowspan="2" style="width:5%">
            <strong>No</strong>
          </th>
          <th rowspan="2" style="width:30%">
            <strong>Mata Pelajaran</strong>
          </th>
          <th rowspan="2" style="width:10%">
            <strong>KKM</strong>
          </th>
          <th colspan="2" style="width:20%">
            <strong>Pengetahuan</strong>
          </th>
          <th colspan="2" style="width:20%">
            <strong>Keterampilan</strong>
          </th>
        </tr>
        <tr class="text-center">
          <th style="width:10%">
            <strong>Nilai</strong>
          </th>
          <th style="width:10%">
            <strong>Predikat</strong>
          </th>
          <th style="width:10%">
            <strong>Nilai</strong>
          </th>
          <th style="width:10%">
            <strong>Predikat</strong>
          </th>
        </tr>
      </thead>
      <tbody class="text-kecil">
        
          <tbody v-for="(el,j) in data" :key="j" style="display: contents;">

          <tr v-if="el.ganti" class="bg-grey-5">
            <td colspan="7">
              <strong>{{el.ganti}}</strong>
            </td>
          </tr>
          <tr v-else>
          <td>{{ el.number }}</td>
          <td>{{ el.nama_mapel }}</td>
          <td>{{ el.kkm }}</td>
          <template>
            <q-td>
              {{ el.nilai_pengetahuan }}
              <q-popup-edit :value="nilai" title="Masukan Nilai">
                <q-input v-model="el.nilai_pengetahuan" dense autofocus />
              </q-popup-edit>
            </q-td>
            <q-td>
              {{ el.predikat_pengetahuan }}
              <q-popup-edit :value="nilai" title="Masukan Predikat">
                <q-input v-model="el.predikat_pengetahuan" dense autofocus />
              </q-popup-edit>
            </q-td>
            <q-td>
              {{ el.nilai_keterampilan }}
              <q-popup-edit :value="nilai" title="Masukan Nilai">
                <q-input v-model="el.nilai_keterampilan" dense autofocus />
              </q-popup-edit>
            </q-td>
            <q-td>
              {{ el.predikat_keterampilan }}
              <q-popup-edit :value="nilai" title="Masukan Predikat">
                <q-input v-model="el.predikat_keterampilan" dense autofocus />
              </q-popup-edit>
            </q-td>
          </template>
          </tr>
        </tbody>
          </tbody>
      
    </q-markup-table>
  </q-card-section>
</template>
<script>
export default {
  props: ["nilai"],
  data() {
    return {
      data:[]
    };
  },
  async mounted() {
    this.data = JSON.parse(JSON.stringify(this.nilai)) 
    // console.log(this.nilai);
  },
  methods: {
    async simpan() {
      this.$emit("save",this.data)
    }
  },
};
</script>
