<template>
  <q-card-section>
    <q-markup-table
      flat
      bordered
      wrap-cells
      dense
      separator="cell"
      style="width: 100%"
    >
      <thead>
        <tr>
          <th style="width:5%">
            <strong>No</strong>
          </th>
          <th style="width:35%">
            <strong>MATA PELAJARAN</strong>
          </th>
          <th style="width:10%">
            <strong>Aspek</strong>
          </th>
          <th style="width:50%">
            <strong>Deskripsi</strong>
          </th>
        </tr>
      </thead>

      <tbody>
        <tbody v-for="(el,i) in data" :key="i" style="display: contents">
          <tr v-if="el.ganti">
            <td colspan="4">
              <strong>{{el.ganti}}</strong>
            </td>
          </tr>

          <tbody v-else style="display: contents;">
            <tr>
              <td rowspan="2">{{ el.number }}</td>
              <td rowspan="2" style="text-align:center;vertical-align:center">
                {{ el.nama_mapel }}
              </td>
              <td>Pengetahuan</td>
              <template>
                <q-td class="text-lebih-kecil deskripsi_content">
                  {{ el.deskripsi_pengetahuan }}
                  <q-popup-edit :value="nilai" title="Masukan Deskripsi">
                    <q-input
                      v-model="el.deskripsi_pengetahuan"
                      dense
                      autofocus
                      autogrow
                    />
                  </q-popup-edit>
                </q-td>
              </template>
            </tr>
            <tr class="bg-grey-3" >
              <td>Keterampilan</td>
              <template>
                <q-td class="text-lebih-kecil deskripsi_content">
                  {{ el.deskripsi_keterampilan }}
                  <q-popup-edit :value="nilai" title="Masukan Deskripsi">
                    <q-input
                      v-model="el.deskripsi_keterampilan"
                      dense
                      autofocus
                      autogrow
                    />
                  </q-popup-edit>
                </q-td>
              </template>
            </tr>
          </tbody>
        </tbody>
      </tbody>
    </q-markup-table>
  </q-card-section>
</template>
<script>
export default {
  props: ["nilai"],
  data() {
    return {
      data:[]
    };
  },
  mounted() {
    this.data = this.nilai
  },
  
  methods: {
    async simpan() {
      this.$emit("save", this.data)
    },
  }
};
</script>

<style lang="scss" scope>
.deskripsi_content {
  max-height: 70px !important;
}
</style>
