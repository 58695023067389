<template>
  <q-card-section>
    <p>
      <strong>C. Ekstrakulikuler</strong>
    </p>
    <div class="q-ml-lg">
      <q-markup-table
        flat
        bordered
        wrap-cells
        dense
        separator="cell"
        style="width: 100%"
      >
        <thead>
          <tr>
            <th style="width:5%">
              <strong>NO</strong>
            </th>
            <th style="width:35%">
              <strong>Kegiatan</strong>
            </th>
            <th style="width:10%">
              <strong>Nilai</strong>
            </th>
            <th style="width:50%">
              <strong>Deskripsi</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(val, i) in ekskul" :key="i">
            <td>{{ i + 1 }}</td>
            <td>{{ val.nama_ekskul }}</td>
            <template>
              <q-td
                >{{ val.nilai }}
                <q-popup-edit :value="nilai" title="Masukan Nilai">
                  <q-input v-model="val.nilai" dense autofocus autogrow />
                </q-popup-edit>
              </q-td>
            </template>
            <td>{{ val.deskripsi_ekskul }}</td>
          </tr>
        </tbody>
      </q-markup-table>
    </div>
    <div class="q-mt-md">
      <p>
        <strong>D. Prestasi</strong>
      </p>
      <div class="q-ml-lg">
        <q-markup-table
          flat
          bordered
          wrap-cells
          dense
          separator="cell"
          style="width: 100%"
        >
          <thead>
            <tr>
              <th style="width:5%">
                <strong>NO</strong>
              </th>
              <th style="width:35%">
                <strong>Kegiatan</strong>
              </th>
              <th style="width:60%">
                <strong>Deskripsi</strong>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(val, i) in prestasi" :key="i">
              <td>{{ i + 1 }}</td>
              <td>{{ val.bidang }}</td>
              <td>{{ val.nama }}</td>
            </tr>
          </tbody>
        </q-markup-table>
      </div>
    </div>
    <div class="q-mt-md">
      <p>
        <strong>E. Ketidakhadiran</strong>
      </p>
      <div class="q-ml-lg">
        <q-markup-table
          flat
          bordered
          wrap-cells
          dense
          separator="cell"
          style="width: 250px"
        >
          <tbody v-if="siswa" class="text-bold">
            <tr>
              <td>Sakit</td>
              <template>
                <q-td
                  >:{{ siswa.sakit }} hari
                  <q-popup-edit :value="nilai" title="Masukan jumlah">
                    <q-input v-model="siswa.sakit" dense autofocus autogrow />
                  </q-popup-edit>
                </q-td>
              </template>
            </tr>
            <tr>
              <td>Izin</td>
              <template>
                <q-td
                  >:{{ siswa.izin }} hari
                  <q-popup-edit :value="nilai" title="Masukan Nilai">
                    <q-input v-model="siswa.izin" dense autofocus autogrow />
                  </q-popup-edit>
                </q-td>
              </template>
            </tr>
            <tr>
              <td>Alfa</td>
              <template>
                <q-td
                  >:{{ siswa.alfa }} hari
                  <q-popup-edit :value="nilai" title="Masukan jumlah">
                    <q-input v-model="siswa.alfa" dense autofocus autogrow />
                  </q-popup-edit>
                </q-td>
              </template>
            </tr>
          </tbody>
        </q-markup-table>
      </div>
    </div>
    <div class="q-mt-md">
      <p>
        <strong>F. Catatan Wali Kelas</strong>
      </p>
      <div class="q-ml-lg">
        <q-card square flat bordered style="100%">
          <q-card-section
            >{{ catatan.catatan }}
            <q-popup-edit :value="nilai" title="Masukan Catatan">
              <q-input
                bottom-slots
                v-model="catatan.catatan"
                dense
                autofocus
                autogrow
                maxlength="250"
              >
                <template v-if="catatan.catatan" v-slot:hint
                  >{{ catatan.catatan.length }} / 250</template
                >
              </q-input>
            </q-popup-edit>
          </q-card-section>
        </q-card>
      </div>
    </div>
    <div class="q-mt-md">
      <p>
        <strong>G. Tanggapan Orang Tua / Wali</strong>
      </p>
      <div class="q-ml-lg">
        <q-card square flat bordered style="width:100%; height: 5em"></q-card>
      </div>
    </div>
  </q-card-section>
</template>
<script>
export default {
  props: ["siswa"],
  data() {
    return {
      nilai: 0,
      is_mounted: false,
      ekskul: [],
      prestasi: [],
      catatan: [],
      kehadiran: {},
    };
  },
  mounted() {
    this.ekskul = this.siswa.ekskul;
    this.catatan = this.siswa.catatan;
    this.prestasi = this.siswa.prestasi;
    this.kehadiran = {
      sakit: this.siswa.sakit,
      izin: this.siswa.izin,
      alfa: this.siswa.alfa,
    };
  },
  methods: {
    async simpan() {
      let data = {
        ekskul: this.ekskul,
        catatan: this.catatan,
        prestasi: this.prestasi,
        kehadiran: this.kehadiran,
      };
      this.$emit("save", data);
    },
  },
};
</script>
