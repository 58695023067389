import { render, staticRenderFns } from "./Nilai.vue?vue&type=template&id=6c64f9e8&"
import script from "./Nilai.vue?vue&type=script&lang=js&"
export * from "./Nilai.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QMarkupTable from 'quasar/src/components/markup-table/QMarkupTable.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QPopupEdit from 'quasar/src/components/popup-edit/QPopupEdit.js';
import QInput from 'quasar/src/components/input/QInput.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCardSection,QMarkupTable,QTd,QPopupEdit,QInput});
