<template>
  <q-card-section class="q-mt-md">
    <p class="text-bold">A.Sikap</p>
    <div class="q-ml-lg">
      <p class="text-bold">1.Sikap Spiritual</p>
      <q-markup-table
        flat
        bordered
        wrap-cells
        dense
        separator="vertical"
        style="width: 100%"
      >
        <thead>
          <tr>
            <th style="width:20%">
              <strong>Predikat</strong>
            </th>
            <th>
              <strong>Deskripsi</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          <template>
            <q-td class="text-center text-predikat" style="width:20%;">
              <strong>{{ formatPredikat(data.nilai_spiritual) }}</strong>
              <q-popup-edit :value="nilai" title="Masukan Nilai">
                <q-input
                  v-model="data.nilai_spiritual"
                  dense
                  autofocus
                  autogrow
                />
              </q-popup-edit>
            </q-td>
          </template>
          <td>
            {{ data.label }}
            {{ catatanGenerator(data.nilai_spiritual, "spiritual") }}
          </td>
        </tbody>
      </q-markup-table>
      <p class="text-bold q-mt-md">2.Sikap Sosial</p>
      <q-markup-table
        flat
        bordered
        wrap-cells
        dense
        separator="vertical"
        style="width: 100%"
      >
        <thead>
          <tr>
            <th style="width:20%">
              <strong>Predikat</strong>
            </th>
            <th>
              <strong>Deskripsi</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          <template>
            <q-td class="text-center text-predikat" style="width:20%;">
              <strong>{{ formatPredikat(data.nilai_sosial) }}</strong>
              <q-popup-edit :value="nilai" title="Masukan Nilai">
                <q-input v-model="data.nilai_sosial" dense autofocus autogrow />
              </q-popup-edit>
            </q-td>
          </template>
          <td>
            {{ data.label }}
            {{ catatanGenerator(data.nilai_sosial, "sosial") }}
          </td>
        </tbody>
      </q-markup-table>
    </div>
  </q-card-section>
</template>
<script>
export default {
  props: ["siswa"],
  data() {
    return {
      data: {},
      nilai: 0,
      batas: {
        kkm: 75,
        a: 0,
        b: 0,
        c: 0,
        d: 0,
      },
    };
  },
  async mounted() {
    this.data = this.siswa;
    await this.predikatGenerator();
  },
  methods: {
    async simpan() {
      this.$emit("save", this.data);
    },
    catatanGenerator(nilai, aspek) {
      let predikat = this.formatPredikat(nilai);
      if (aspek == "spiritual") {
        if (predikat == "A") {
          return "selalu bersyukur dan berdo'a sebelum melakukan kegiatan, ketaatan dan kesadaran beribadahnya sangat baik";
        } else if (predikat == "B") {
          return "selalu bersyukur dan berdo'a sebelum melakukan kegiatan, ketaatan dan kesadaran beribadahnya baik";
        } else if (predikat == "C") {
          return "sering bersyukur dan berdo'a sebelum melakukan kegiatan, ketaatan dan kesadaran beribadahnya mulai berkembang";
        } else if (predikat == "D") {
          return "sering bersyukur dan berdo'a sebelum melakukan kegiatan, ketaatan dan kesadaran beribadahnya mulai muncul";
        }
      } else if (aspek == "sosial") {
        if (predikat == "A") {
          return "sangat santun, percaya diri dan reponsif dalam pergaulan dan kepedulian sosial; kejujuran, kedisiplinan dan tanggung jawabnyapun sangat baik";
        } else if (predikat == "B") {
          return "bersikap santun, percaya diri dan reponsif dalam pergaulan dan kepedulian sosial; kejujuran, kedisiplinan dan tanggung jawabnyapun baik";
        } else if (predikat == "C") {
          return "sering bersikap santun, percaya diri dan reponsif dalam pergaulan dan kepedulian sosial; kejujuran, kedisiplinan dan tanggung jawabnya mulai berkembang";
        } else if (predikat == "D") {
          return "terkadang bersikap santun, percaya diri dan reponsif dalam pergaulan dan kepedulian sosial; kejujuran, kedisiplinan dan tanggung jawabnya mulai muncul";
        }
      }
    },
    predikatGenerator() {
      let tempBatas = JSON.parse(JSON.stringify(this.batas));
      let patok = 100 - parseFloat(tempBatas.kkm);
      let interval = patok / 3;
      let curNilai = parseFloat(tempBatas.kkm);
      tempBatas.c = curNilai;
      curNilai = curNilai + interval;
      tempBatas.b = Math.floor(curNilai);
      curNilai = curNilai + interval;
      tempBatas.a = Math.floor(curNilai);
      this.batas = tempBatas;
    },
    formatPredikat(nilai) {
      nilai = parseFloat(nilai);
      if (nilai < this.batas.kkm) {
        return "D";
      } else if (nilai >= this.batas.kkm && nilai < this.batas.b) {
        return "C";
      } else if (nilai >= this.batas.b && nilai < this.batas.a) {
        return "B";
      } else if (nilai >= this.batas.a && nilai <= 100) {
        return "A";
      }
    },
  },
};
</script>

<style lang="scss"></style>
