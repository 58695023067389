<template>
  <q-list dense class="q-ml-lg">
    <q-item dense>
      <q-item-section class="text-kecil">
        <p class="q-ma-none row justify-end" style="height:20px"></p>
        <p class="q-ma-none row justify-end" style="height:20px">
          <strong>JUMLAH NILAI</strong>
        </p>
        <p class="q-ma-none row justify-end" style="height:20px">
          <strong>RATA-RATA NILAI</strong>
        </p>
        <p class="q-ma-none row justify-end" style="height:20px">
          <strong>NILAI RATA-RATA KELAS</strong>
        </p>
      </q-item-section>
      <q-item-section class="text-lebih-kecil">
        <p class="q-ma-none row justify-center" style="height:20px">
          <strong>Pengetahuan</strong>
        </p>
        <p class="q-ma-none row justify-center" style="height:20px">
          {{ siswa.jumlah.pengetahuan }}
        </p>
        <p class="q-ma-none row justify-center" style="height:20px">
          {{ siswa.rerata.pengetahuan }}
        </p>
        <p class="q-ma-none row justify-center" style="height:20px">
          {{ siswa.rerata_pengetahuan }}
        </p>
      </q-item-section>
      <q-item-section class="text-lebih-kecil">
        <p class="q-ma-none row justify-center" style="height:20px">
          <strong>Keterampilan</strong>
        </p>
        <p class="q-ma-none row justify-center" style="height:20px">
          {{ siswa.jumlah.keterampilan }}
        </p>
        <p class="q-ma-none row justify-center" style="height:20px">
          {{ siswa.rerata.keterampilan }}
        </p>
        <p class="q-ma-none row justify-center" style="height:20px">
          {{ siswa.rerata_keterampilan }}
        </p>
      </q-item-section>
      <q-item-section class="text-lebih-kecil">
        <p class="q-ma-none row justify-center" style="height:20px">
          <strong>Rata-Rata</strong>
        </p>
        <p class="q-ma-none row justify-center" style="height:20px">
          {{
            (parseFloat(siswa.jumlah.pengetahuan) +
              parseFloat(siswa.jumlah.keterampilan)) /
              2
          }}
        </p>
        <p class="q-ma-none row justify-center" style="height:20px">
          {{
            parseFloat(
              (parseFloat(siswa.rerata.pengetahuan) +
                parseFloat(siswa.rerata.keterampilan)) /
                2
            ).toFixed(2)
          }}
        </p>
        <p class="q-ma-none row justify-center" style="height:20px">
          {{ parseFloat(siswa.rerata_total).toFixed(2) }}
        </p>
      </q-item-section>
    </q-item>
  </q-list>
</template>

<script>
export default {
  props: ["siswa"],
  data() {
    return {
      show: false,
    };
  },
  watch: {
    siswa: {
      deep: true,
      handler(val) {
        if (val.rerata) {
          this.show = true;
        }
      },
    },
  },
  methods: {},
};
</script>
