<template>
  <q-card-section>
    <p class="text-bold q-mb-none">B. Pengetahuan dan Keterampilan</p>
    <q-markup-table
      class="q-ml-lg"
      flat
      bordered
      wrap-cells
      dense
      separator="cell"
      style="width: 100%"
    >
      <thead>
        <tr class="text-center">
          <th rowspan="2" style="width:5%">
            <strong>No</strong>
          </th>
          <th rowspan="2" style="width:30%">
            <strong>Mata Pelajaran</strong>
          </th>
          <th rowspan="2" style="width:10%">
            <strong>KKM</strong>
          </th>
          <th colspan="2" style="width:20%">
            <strong>Pengetahuan</strong>
          </th>
          <th colspan="2" style="width:20%">
            <strong>Keterampilan</strong>
          </th>
        </tr>
        <tr class="text-center">
          <th style="width:10%">
            <strong>Nilai</strong>
          </th>
          <th style="width:10%">
            <strong>Predikat</strong>
          </th>
          <th style="width:10%">
            <strong>Nilai</strong>
          </th>
          <th style="width:10%">
            <strong>Predikat</strong>
          </th>
        </tr>
      </thead>
      <tbody class="text-kecil">
        <tr class="bg-grey-5">
          <td colspan="7">
            <strong>Kelompok A (Wajib)</strong>
          </td>
        </tr>
        <tr v-for="val in kelompok.A" :key="val.id">
          <td>{{ val.number }}</td>
          <td>{{ val.nama_mapel }}</td>
          <td>{{ val.kkm }}</td>
          <template>
            <q-td>
              {{ val.nilai_pengetahuan }}
              <q-popup-edit :value="nilai" title="Masukan Nilai">
                <q-input v-model="val.nilai_pengetahuan" dense autofocus />
              </q-popup-edit>
            </q-td>
            <q-td>
              {{ val.predikat_pengetahuan }}
              <q-popup-edit :value="nilai" title="Masukan Predikat">
                <q-input v-model="val.predikat_pengetahuan" dense autofocus />
              </q-popup-edit>
            </q-td>
            <q-td>
              {{ val.nilai_keterampilan }}
              <q-popup-edit :value="nilai" title="Masukan Nilai">
                <q-input v-model="val.nilai_keterampilan" dense autofocus />
              </q-popup-edit>
            </q-td>
            <q-td>
              {{ val.predikat_keterampilan }}
              <q-popup-edit :value="nilai" title="Masukan Predikat">
                <q-input v-model="val.predikat_keterampilan" dense autofocus />
              </q-popup-edit>
            </q-td>
          </template>
        </tr>
        <tr class="bg-grey-5">
          <td colspan="7">
            <strong>Kelompok B (Wajib)</strong>
          </td>
        </tr>
        <tr v-for="val in kelompok.B" :key="val.id">
          <td>{{ val.number }}</td>
          <td>{{ val.nama_mapel }}</td>
          <td>{{ val.kkm }}</td>
          <template>
            <q-td>
              {{ val.nilai_pengetahuan }}
              <q-popup-edit :value="nilai" title="Masukan Nilai">
                <q-input v-model="val.nilai_pengetahuan" dense autofocus />
              </q-popup-edit>
            </q-td>
            <q-td>
              {{ val.predikat_pengetahuan }}
              <q-popup-edit :value="nilai" title="Masukan Predikat">
                <q-input v-model="val.predikat_pengetahuan" dense autofocus />
              </q-popup-edit>
            </q-td>
            <q-td>
              {{ val.nilai_keterampilan }}
              <q-popup-edit :value="nilai" title="Masukan Nilai">
                <q-input v-model="val.nilai_keterampilan" dense autofocus />
              </q-popup-edit>
            </q-td>
            <q-td>
              {{ val.predikat_keterampilan }}
              <q-popup-edit :value="nilai" title="Masukan Predikat">
                <q-input v-model="val.predikat_keterampilan" dense autofocus />
              </q-popup-edit>
            </q-td>
          </template>
        </tr>
        <tr class="bg-grey-5">
          <td colspan="7">
            <strong>Kelompok C (Peminatan)</strong>
          </td>
        </tr>
        <tr v-for="val in kelompok.C" :key="val.id">
          <td>{{ val.number }}</td>
          <td>{{ val.nama_mapel }}</td>
          <td>{{ val.kkm }}</td>
          <template>
            <q-td>
              {{ val.nilai_pengetahuan }}
              <q-popup-edit :value="nilai" title="Masukan Nilai">
                <q-input v-model="val.nilai_pengetahuan" dense autofocus />
              </q-popup-edit>
            </q-td>
            <q-td>
              {{ val.predikat_pengetahuan }}
              <q-popup-edit :value="nilai" title="Masukan Predikat">
                <q-input v-model="val.predikat_pengetahuan" dense autofocus />
              </q-popup-edit>
            </q-td>
            <q-td>
              {{ val.nilai_keterampilan }}
              <q-popup-edit :value="nilai" title="Masukan Nilai">
                <q-input v-model="val.nilai_keterampilan" dense autofocus />
              </q-popup-edit>
            </q-td>
            <q-td>
              {{ val.predikat_keterampilan }}
              <q-popup-edit :value="nilai" title="Masukan Predikat">
                <q-input v-model="val.predikat_keterampilan" dense autofocus />
              </q-popup-edit>
            </q-td>
          </template>
        </tr>
        <tr class="bg-grey-5">
          <td colspan="7">
            <strong>LINTAS MINAT</strong>
          </td>
        </tr>
        <tr v-for="val in kelompok.C_LIMIT" :key="val.id">
          <td>{{ val.number }}</td>
          <td>{{ val.nama_mapel }}</td>
          <td>{{ val.kkm }}</td>
          <template>
            <q-td>
              {{ val.nilai_pengetahuan }}
              <q-popup-edit :value="nilai" title="Masukan Nilai">
                <q-input v-model="val.nilai_pengetahuan" dense autofocus />
              </q-popup-edit>
            </q-td>
            <q-td>
              {{ val.predikat_pengetahuan }}
              <q-popup-edit :value="nilai" title="Masukan Predikat">
                <q-input v-model="val.predikat_pengetahuan" dense autofocus />
              </q-popup-edit>
            </q-td>
            <q-td>
              {{ val.nilai_keterampilan }}
              <q-popup-edit :value="nilai" title="Masukan Nilai">
                <q-input v-model="val.nilai_keterampilan" dense autofocus />
              </q-popup-edit>
            </q-td>
            <q-td>
              {{ val.predikat_keterampilan }}
              <q-popup-edit :value="nilai" title="Masukan Predikat">
                <q-input v-model="val.predikat_keterampilan" dense autofocus />
              </q-popup-edit>
            </q-td>
          </template>
        </tr>
        <tr class="bg-grey-5">
          <td colspan="7">
            <strong>Kelompok D (Kekhasan)</strong>
          </td>
        </tr>
        <tr v-for="val in kelompok.D" :key="val.id">
          <td>{{ val.number }}</td>
          <td>{{ val.nama_mapel }}</td>
          <td>{{ val.kkm }}</td>
          <template>
            <q-td>
              {{ val.nilai_pengetahuan }}
              <q-popup-edit :value="nilai" title="Masukan Nilai">
                <q-input v-model="val.nilai_pengetahuan" dense autofocus />
              </q-popup-edit>
            </q-td>
            <q-td>
              {{ val.predikat_pengetahuan }}
              <q-popup-edit :value="nilai" title="Masukan Predikat">
                <q-input v-model="val.predikat_pengetahuan" dense autofocus />
              </q-popup-edit>
            </q-td>
            <q-td>
              {{ val.nilai_keterampilan }}
              <q-popup-edit :value="nilai" title="Masukan Nilai">
                <q-input v-model="val.nilai_keterampilan" dense autofocus />
              </q-popup-edit>
            </q-td>
            <q-td>
              {{ val.predikat_keterampilan }}
              <q-popup-edit :value="nilai" title="Masukan Predikat">
                <q-input v-model="val.predikat_keterampilan" dense autofocus />
              </q-popup-edit>
            </q-td>
          </template>
        </tr>
      </tbody>
    </q-markup-table>
  </q-card-section>
</template>
<script>
export default {
  props: ["siswa"],
  data() {
    return {
      nilai: 0,
      kelompok: {
        A: [],
        B: [],
        C: [],
        C_LIMIT: [],
        D: []
      }
    };
  },
  async mounted() {},
  watch: {
    siswa: {
      deep: true,
      handler(val) {
        if (val.id) {
          this.prepareNilai();
        }
      }
    }
  },
  methods: {
    async simpan() {
      let temp = [];
      for (let item of this.kelompok.A) {
        temp.push(item);
      }
      for (let item of this.kelompok.B) {
        temp.push(item);
      }
      for (let item of this.kelompok.C) {
        temp.push(item);
      }
      for (let item of this.kelompok.C_LIMIT) {
        temp.push(item);
      }
      for (let item of this.kelompok.D) {
        temp.push(item);
      }
      await this.$http.put(
        `/akademik/riwayat_ekskul/simpan_detail/NILAI`,
        temp
      );
    },
    async prepareNilai() {
      let temp = {
        A: [],
        B: [],
        C: [],
        C_LIMIT: [],
        D: []
      };
      let resp = await this.$http.get(
        `/akademik/riwayat_raport/getNilai/${this.siswa.id}`
      );
      temp.A = resp.data.filter(x => {
        return x.tipe == "A";
      });
      temp.B = resp.data.filter(x => {
        return x.tipe == "B";
      });
      temp.C = resp.data.filter(x => {
        return x.tipe == "C";
      });
      temp.C_LIMIT = resp.data.filter(x => {
        return x.tipe == "C_LIMIT";
      });
      temp.D = resp.data.filter(x => {
        return x.tipe == "D";
      });
      let c = 0;

      for (let item of temp.A) {
        c++;
        item.number = c;
      }
      for (let item of temp.B) {
        c++;
        item.number = c;
      }
      for (let item of temp.C) {
        c++;
        item.number = c;
      }
      for (let item of temp.C_LIMIT) {
        c++;
        item.number = c;
      }
      for (let item of temp.D) {
        c++;
        item.number = c;
      }

      this.kelompok = temp;
    }
  }
};
</script>
