<template>
  <div
    class="main row justify-center items-start bg-grey-7 q-gutter-xl"
    style="font-size:12px;"
  >
    <q-card flat bordered style="width:210mm; " class="q-mt-xl ">
      <q-card-section class="row justify-between">
        <div class="row q-gutter-sm">
          <q-select
            outlined
            label="Tahun Ajaran"
            :options="listTahun"
            v-model="selTahun"
            @input="getData"
            style="width:300px"
          ></q-select>
          <q-btn
            unelevated
            :disable="selTahun == null"
            color="primary"
            label="SIMPAN"
            @click="
              confirm = true;
              pass =
                'SAYA YAKIN INGIN MENGUBAH RAPORT ATAS NAMA ' +
                selSiswa.label +
                ' PERIODE ' +
                selTahun.label;
            "
          ></q-btn>
        </div>
        <q-btn
          dense
          outline
          :disable="selTahun == null"
          color="primary"
          label="Download PDF"
          @click="download()"
        />
      </q-card-section>
    </q-card>

    <q-card v-if="selSiswa" style="width: 210mm;">
      <div class="paper" ref="cover">
        <q-card flat class=" column justify-between" style="height: 330mm">
          <q-card-section class="column items-center">
            <p style="font-size:18px">
              <strong>LAPORAN PENCAPAIAN HASIL BELAJAR PESERTA DIDIK</strong>
            </p>
            <p style="font-size:18px">
              <strong>MADRASAH 'ALIYAH SWASTA (MAS)</strong>
            </p>
            <p class="text-overline" style="font-size:14px">
              PERGURUAN ISLAM AR RISALAH
            </p>
          </q-card-section>
          <q-card-section
            class="column justify-evenly text-center"
            style="height:225mm"
          >
            <div>
              <img src="../../public/img/logo.png" style="height:75mm" />
            </div>
            <div v-if="selSiswa">
              <p class="q-mb-xs" style="font-size:14px">
                <strong>Nama Peserta Didik:</strong>
              </p>
              <p style="font-size:18px">
                <strong>{{ selSiswa.label }}</strong>
              </p>
              <p class="q-mb-xs" style="font-size:14px">
                <strong>NIS/NISN:</strong>
              </p>
              <p v-if="selSiswa.nisn != null" style="font-size:18px">
                <strong>{{ selSiswa.nisn }}</strong>
              </p>
              <p v-else style="font-size:18px">
                <strong>N/A</strong>
              </p>
            </div>
          </q-card-section>
          <q-card-section class="text-center">
            <p style="font-size:18px">
              <strong>KEMENTRIAN AGAMA</strong>
            </p>
            <p style="font-size:18px">
              <strong>REPUBLIK INDONESIA</strong>
            </p>
          </q-card-section>
        </q-card>
      </div>
      <div class="paper" ref="alamat">
        <q-card flat class=" column " style="height: 330mm">
          <q-card-section class="text-center">
            <p style="font-size:18px">
              <strong>LAPORAN PENCAPAIAN PESERTA DIDIK</strong>
            </p>
            <p style="font-size:18px">
              <strong>MADRASAH 'ALIYAH</strong>
            </p>
            <p style="font-size:18px">
              <strong>MA</strong>
            </p>
          </q-card-section>
          <q-card-section class="q-px-none">
            <q-markup-table dense flat>
              <tr>
                <td>Nama Sekolah</td>
                <td>:</td>
                <td style="width:75%">
                  <q-input
                    dense
                    readonly
                    value="Madrasah 'Aliyah Swasta (MAS) Perguruan Islam Ar-Risalah"
                  ></q-input>
                </td>
              </tr>
              <tr>
                <td>NPSN</td>
                <td>:</td>
                <td style="width:75%">
                  <q-input dense readonly value="10263742"></q-input>
                </td>
              </tr>
              <tr>
                <td>NIS/NSM</td>
                <td>:</td>
                <td style="width:75%">
                  <q-input dense readonly value="305/131213710005"></q-input>
                </td>
              </tr>
              <tr>
                <td>Alamat Sekolah</td>
                <td>:</td>
                <td style="width:75%">
                  <q-input
                    dense
                    readonly
                    value="Kampus Ar Risalah Air Dingin RT .01 RW .IX"
                  ></q-input>
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td class="row justify-between" style="width:75%">
                  <div
                    class="row justify-between items-center"
                    style="width:35%"
                  >
                    <span>Kode Pos</span>
                    <q-input
                      dense
                      readonly
                      value="25174"
                      style="width:65%"
                    ></q-input>
                  </div>
                  <div
                    class="row justify-between items-center"
                    style="width:60%"
                  >
                    <span>Telpon</span>
                    <q-input
                      dense
                      readonly
                      value="0751-7877114, 9824888"
                    ></q-input>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Desa/Kelurahan</td>
                <td>:</td>
                <td style="width:75%">
                  <q-input dense readonly value="Balai Gadang"></q-input>
                </td>
              </tr>
              <tr>
                <td>Kecamatan</td>
                <td>:</td>
                <td style="width:75%">
                  <q-input dense readonly value="Koto Tengah"></q-input>
                </td>
              </tr>
              <tr>
                <td>Kota/Kabupaten</td>
                <td>:</td>
                <td style="width:75%">
                  <q-input dense readonly value="Padang"></q-input>
                </td>
              </tr>
              <tr>
                <td>Provinsi</td>
                <td>:</td>
                <td style="width:75%">
                  <q-input dense readonly value="Sumatera Barat"></q-input>
                </td>
              </tr>
              <tr>
                <td>Website</td>
                <td>:</td>
                <td style="width:75%">
                  <q-input
                    dense
                    readonly
                    value="http://www.arrisalah-sumber.sch.id"
                  ></q-input>
                </td>
              </tr>
              <tr>
                <td>E-mail</td>
                <td>:</td>
                <td style="width:75%">
                  <q-input
                    dense
                    readonly
                    value="mas-arrisalah@yahoo.co.id"
                  ></q-input>
                </td>
              </tr>
            </q-markup-table>
          </q-card-section>
        </q-card>
      </div>
      <div ref="sikap" class="paper column justify-between no-wrap">
        <Header :siswa="selSiswa"></Header>

        <Sikap :siswa="selSiswa" @save="mergeSikap" ref="nilai_sikap"></Sikap>
        <Footer class="q-mt-md" :siswa="selSiswa"></Footer>
      </div>
      <div v-if="ready">
        <span v-for="(val, i) in selSiswa.newNilai" :key="i">
          <div ref="nilai" class="paper column justify-between no-wrap">
            <div>
              <Header :siswa="selSiswa"></Header>
              <p v-if="i == 0" class="text-bold q-mb-none">
                B. Pengetahuan dan Keterampilan
              </p>
              <newNilai
                :nilai="val"
                @save="mergeNilai"
                ref="nilai_mapel"
              ></newNilai>
              <Rerata
                v-if="i == selSiswa.newNilai.length - 1"
                :siswa="selSiswa"
              ></Rerata>
            </div>
            <Footer
              v-if="i == selSiswa.newNilai.length - 1"
              class="q-mt-md"
              :siswa="selSiswa"
            ></Footer>
          </div>
        </span>

        <span v-for="(val, i) in selSiswa.newDesc" :key="i">
          <div ref="deskripsi" class="paper column justify-between no-wrap">
            <div>
              <Header :siswa="selSiswa"></Header>
              <p v-if="i == 0" class="text-bold q-mb-none">
                Deskripsi Pengetahuan dan Keterampilan
              </p>
              <newDeskripsi
                :nilai="val"
                @save="mergeDesc"
                ref="nilai_deskripsi"
              ></newDeskripsi>
            </div>
            <Footer
              v-if="i == selSiswa.newDesc.length - 1"
              class="q-mt-md"
              :siswa="selSiswa"
            ></Footer>
          </div>
        </span>
        <div ref="lainya" class="paper column justify-between no-wrap">
          <div>
            <Header :siswa="selSiswa"></Header>
            <Lainya
              :siswa="selSiswa"
              ref="nilai_lainya"
              @save="mergeLainya"
            ></Lainya>
          </div>
          <Footer :siswa="selSiswa" class="q-mt-md"></Footer>
        </div>
      </div>
    </q-card>
    <q-dialog v-model="confirm" v-if="selSiswa">
      <q-card>
        <q-card-section v-if="selSiswa.id">
          <p>Apa anda yakin ingin menyimpan perubahan?</p>
          Tulis "SAYA YAKIN INGIN MENGUBAH RAPORT ATAS NAMA
          {{ selSiswa.label }} PERIODE {{ selTahun.label }}" tanpa tanda kutip
        </q-card-section>
        <q-card-section>
          <q-input outlined v-model="keyword"></q-input>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            flat
            color="primary"
            label="Tidak"
            @click="
              pass = '';
              keyword = '';
            "
            v-close-popup
          ></q-btn>
          <q-btn
            unelevated
            color="primary"
            label="Ya"
            :disable="pass.toLowerCase() != keyword.toLowerCase()"
            @click="simpan_raport"
            v-close-popup
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import { mapGetters } from "vuex";
import moment from "moment";
import { jsPDF } from "jspdf";
import Header from "@/views/HasilRaportDigital/Header";
import Sikap from "@/views/HasilRaportDigital/Sikap";
import Nilai from "@/views/HasilRaportDigital/Nilai";
import Rerata from "@/views/HasilRaportDigital/Rerata";
import Deskripsi from "@/views/HasilRaportDigital/Deskripsi";
import newNilai from "@/views/NewRaportDigital/Nilai";
import newDeskripsi from "@/views/NewRaportDigital/Deskripsi";
import Lainya from "@/views/HasilRaportDigital/Lainya";
import Footer from "@/views/HasilRaportDigital/Footer";

export default {
  components: {
    Header,
    Sikap,
    Nilai,
    Rerata,
    Footer,
    Deskripsi,
    Lainya,
    newNilai,
    newDeskripsi,
  },
  data() {
    return {
      ready: false,
      gambar: null,
      confirm: false,
      user: {},
      pass: "",
      keyword: "",

      nilaiKelompok_A: [],
      nilaiKelompok_B: [],
      nilaiKelompok_C: [],
      nilaiLimit: [],
      nilaiKelompok_D: [],

      newformat: [],
      newdeskripsi: [],

      kehadiran: { sakit: 0, izin: 0, alfa: 0 },

      selSiswa: null,
      siswa: [],

      listTahun: [],
      selTahun: null,
    };
  },
  async created() {
    this.$q.loading.show({ message: "Mohon Tunggu..." });
    this.$q.loading.show({
      message: "Memuat Data....",
    });
    await this.getTahunAjaran();
    await this.getUser();
    this.$q.loading.hide();
    this.$q.loading.hide();
  },
  methods: {
    ...mapGetters(["getCurrentUser"]),
    async getUser() {
      this.user = await this.getCurrentUser();
    },
    async simpan_raport() {
      this.$q.loading.show({ message: "Menyimpan Data..." });
      await this.$refs.nilai_sikap.simpan();
      for (let i in this.$refs.nilai_mapel) {
        await this.$refs.nilai_mapel[i].simpan();
      }
      for (let i in this.$refs.nilai_deskripsi) {
        await this.$refs.nilai_deskripsi[i].simpan();
      }
      await this.$refs.nilai_lainya.simpan();
      this.selSiswa.id_user = this.user.id;
      this.selSiswa.tanggal = moment().format("YYYY-MM-DD");
      await this.$http.put(
        `/akademik/riwayat_raport/simpanperubahanall`,
        this.selSiswa
      );
      await this.getData();
      this.pass = "";
      this.keyword = "";
      this.$q.loading.hide();
      this.$q.notify({
        message: "Raport Berhasil Disimpan",
        color: "positive",
      });
    },
    mergeSikap(data) {
      let temp = JSON.parse(JSON.stringify(this.selSiswa));
      temp.nilai_sosial = data.nilai_sosial;
      temp.nilai_spiritual = data.nilai_spiritual;
      this.selSiswa = temp;
    },
    mergeNilai(data) {
      let temp = JSON.parse(JSON.stringify(this.selSiswa.listNilai));
      for (let item of data) {
        let objIndex = temp.findIndex((obj) => obj.id == item.id);
        if (objIndex != -1) {
          temp[objIndex].nilai_pengetahuan = item.nilai_pengetahuan;
          temp[objIndex].nilai_keterampilan = item.nilai_keterampilan;
          temp[objIndex].deskripsi_pengetahuan = item.predikat_pengetahuan;
          temp[objIndex].deskripsi_keterampilan = item.predikat_keterampilan;
        }
      }
      this.selSiswa.listNilai = temp;
    },
    mergeDesc(data) {
      let temp = JSON.parse(JSON.stringify(this.selSiswa.listNilai));
      for (let item of data) {
        let objIndex = temp.findIndex((obj) => obj.id == item.id);
        if (objIndex != -1) {
          temp[objIndex].deskripsi_pengetahuan = item.deskripsi_pengetahuan;
          temp[objIndex].deskripsi_keterampilan = item.deskripsi_keterampilan;
        }
      }
      this.selSiswa.listNilai = temp;
    },
    mergeLainya(data) {
      let temp = JSON.parse(JSON.stringify(this.selSiswa));
      temp.ekskul = data.ekskul;
      temp.catatan = data.catatan;
      temp.prestasi = data.prestasi;
      this.siswa.sakit = data.kehadiran.sakit;
      this.siswa.izin = data.kehadiran.izin;
      this.siswa.alfa = data.kehadiran.alfa;
      this.selSiswa = temp;
    },
    async getTahunAjaran() {
      let resp = await this.$http.get(
        `/akademik/riwayat_raport/getTahunAjaran/${this.$route.params.id}`
      );
      for (let item of resp.data) {
        item.label = `${item.nama} - Semester ${item.semester}`;
      }
      this.listTahun = resp.data;
    },
    async getData() {
      let resp = await this.$http.get(
        `/akademik/riwayat_raport/getData/${this.$route.params.id}/${this.selTahun.id_tahun_ajaran}`
      );
      resp.data.alamat_detail = `${resp.data.alamat} Kel.${resp.data.kelurahan} ${resp.data.kecamatan}`;
      resp.data.tahun_ajar = this.selTahun.nama;
      resp.data.semester = this.selTahun.semester;
      resp.data.id_tahun_ajaran = this.selTahun.id_tahun_ajaran;
      resp.data.id_user = 1;
      this.selSiswa = resp.data;
      // console.log(this.selSiswa);
      await this.newGetNilai();
    },
    async newGetNilai() {
      await this.getNilaiSiswa();
      this.getPageNilai();
      this.getPageDesc();
      this.getRerata();
      await this.getEkskul();
      await this.getCatatan();
      await this.getPrestasi();
      console.log(this.selSiswa);
      this.ready = true;
      this.resizeFont();
    },
    resize(page) {
      // console.log("page");
      let hScroll = page.scrollHeight;
      let hClient = page.clientHeight;

      let ratio = 1.0;
      while (hScroll > hClient) {
        ratio -= 0.01;
        page.style.setProperty("font-size", ratio + "em", "important");
        hScroll = page.scrollHeight;
        hClient = page.clientHeight;
      }
    },
    resizeFont() {
      // console.log(this.$refs);

      // this.resize(this.$refs.cover);
      this.$nextTick(() => {
        for (let i in this.$refs.nilai) {
          this.resize(this.$refs.nilai[i]);
        }

        for (let i in this.$refs.deskripsi) {
          this.resize(this.$refs.deskripsi[i]);
        }
        this.resize(this.$refs.lainya);
      });
      this.resize(this.$refs.sikap);
      // this.resize(this.$refs.alamat);
    },
    async getEkskul() {
      let resp = await this.$http.get(
        `/akademik/riwayat_raport/getEkskul/${this.selSiswa.id_siswa}/${this.selSiswa.id_tahun_ajaran}`
      );
      for (let item of resp.data) {
        if (item.nilai >= 91) {
          item.deskripsi_ekskul =
            "Sangat Memuaskan. Sangat Aktif dalam Kegiatan";
        } else if (item.nilai < 91 && item.nilai >= 83) {
          item.deskripsi_ekskul = "Memuaskan. Aktif dalam Kegiatan";
        } else if (item.nilai < 83 && item.nilai >= 75) {
          item.deskripsi_ekskul = "Aktif dalam Kegiatan";
        } else {
          item.deskripsi_ekskul = "Tidak Aktif dalam Kegiatan";
        }
      }
      this.selSiswa.ekskul = resp.data;
    },
    async getCatatan() {
      let resp = await this.$http.get(
        `/akademik/riwayat_raport/getCatatan/${this.selSiswa.id_siswa}/${this.selSiswa.id_tahun_ajaran}`
      );
      this.selSiswa.catatan = resp.data;
    },
    async getPrestasi() {
      let resp = await this.$http.get(
        `/akademik/riwayat_raport/getPrestasi/${this.selSiswa.id_siswa}/${this.selSiswa.id_tahun_ajaran}`
      );
      this.selSiswa.prestasi = resp.data;
    },
    async getNilaiSiswa() {
      let resp = await this.$http.get(
        `/akademik/riwayat_raport/getnilaisiswa/${this.selSiswa.id}`
      );
      this.nilaiKelompok_A = resp.data.filter((x) => {
        return x.tipe == "A";
      });
      this.nilaiKelompok_B = resp.data.filter((x) => {
        return x.tipe == "B";
      });
      this.nilaiKelompok_C = resp.data.filter((x) => {
        return x.tipe == "C";
      });
      this.nilaiLimit = resp.data.filter((x) => {
        return x.tipe == "C_LIMIT";
      });
      this.nilaiKelompok_D = resp.data.filter((x) => {
        return x.tipe == "D";
      });
      let c = 0;

      for (let item of this.nilaiKelompok_A) {
        c++;
        item.number = c;
      }
      for (let item of this.nilaiKelompok_B) {
        c++;
        item.number = c;
      }
      for (let item of this.nilaiKelompok_C) {
        c++;
        item.number = c;
      }
      for (let item of this.nilaiLimit) {
        c++;
        item.number = c;
      }
      for (let item of this.nilaiKelompok_D) {
        c++;
        item.number = c;
      }
      this.selSiswa.listNilai = resp.data;
    },
    getRerata() {
      let jumlah = {
        pengetahuan: 0,
        keterampilan: 0,
      };
      let rerata = {
        pengetahuan: 0,
        keterampilan: 0,
      };
      let count = 0;

      for (let item of this.selSiswa.listNilai) {
        jumlah.pengetahuan =
          jumlah.pengetahuan + parseFloat(item.nilai_pengetahuan);
        jumlah.keterampilan =
          jumlah.keterampilan + parseFloat(item.nilai_keterampilan);
        count++;
      }
      rerata.pengetahuan = parseFloat(
        parseFloat(jumlah.pengetahuan) / count
      ).toFixed(2);
      rerata.keterampilan = parseFloat(
        parseFloat(jumlah.keterampilan) / count
      ).toFixed(2);

      this.selSiswa.jumlah = jumlah;
      this.selSiswa.rerata = rerata;
    },
    getPageNilai() {
      let count = 0;
      let idx = 0;
      let new_nilai = [];

      new_nilai.push([]);
      count++;
      new_nilai[idx].push({ ganti: "Kelompok A (Wajib)" });
      for (let item of this.nilaiKelompok_A) {
        if (count >= 20) {
          count = 0;
          idx++;
          new_nilai.push([]);
        }
        new_nilai[idx].push(item);
        count++;
      }
      count++;
      new_nilai[idx].push({ ganti: "Kelompok B (Wajib)" });
      for (let item of this.nilaiKelompok_B) {
        if (count >= 20) {
          count = 0;
          idx++;
          new_nilai.push([]);
        }
        new_nilai[idx].push(item);
        count++;
      }
      count++;
      new_nilai[idx].push({ ganti: "Kelompok C (Peminatan)" });
      for (let item of this.nilaiKelompok_C) {
        if (count >= 20) {
          count = 0;
          idx++;
          new_nilai.push([]);
        }
        new_nilai[idx].push(item);
        count++;
      }
      count++;
      new_nilai[idx].push({ ganti: "Lintas Minat" });
      for (let item of this.nilaiLimit) {
        if (count >= 20) {
          count = 0;
          idx++;
          new_nilai.push([]);
        }
        new_nilai[idx].push(item);
        count++;
      }
      count++;
      new_nilai[idx].push({ ganti: "Kelompok D (Kekhasan)" });
      for (let item of this.nilaiKelompok_D) {
        if (count >= 20) {
          count = 0;
          idx++;
          new_nilai.push([]);
        }
        new_nilai[idx].push(item);
        count++;
      }
      this.selSiswa.newNilai = new_nilai;
      // console.log(new_nilai);
    },
    getPageDesc() {
      let count = 0;
      let idx = 0;
      let new_desc = [];

      new_desc.push([]);
      count++;
      new_desc[idx].push({ ganti: "Kelompok A (Wajib)" });
      for (let item of this.nilaiKelompok_A) {
        if (count >= 7) {
          count = 0;
          idx++;
          new_desc.push([]);
        }
        new_desc[idx].push(item);
        count++;
      }
      count++;
      new_desc[idx].push({ ganti: "Kelompok B (Wajib)" });
      for (let item of this.nilaiKelompok_B) {
        if (count >= 7) {
          count = 0;
          idx++;
          new_desc.push([]);
        }
        new_desc[idx].push(item);
        count++;
      }
      count++;
      new_desc[idx].push({ ganti: "Kelompok C (Peminatan)" });
      for (let item of this.nilaiKelompok_C) {
        if (count >= 7) {
          count = 0;
          idx++;
          new_desc.push([]);
        }
        new_desc[idx].push(item);
        count++;
      }
      count++;
      new_desc[idx].push({ ganti: "Lintas Minat" });
      for (let item of this.nilaiLimit) {
        if (count >= 7) {
          count = 0;
          idx++;
          new_desc.push([]);
        }
        new_desc[idx].push(item);
        count++;
      }
      count++;
      new_desc[idx].push({ ganti: "Kelompok D (Kekhasan)" });
      for (let item of this.nilaiKelompok_D) {
        if (count >= 7) {
          count = 0;
          idx++;
          new_desc.push([]);
        }
        new_desc[idx].push(item);
        count++;
      }
      this.selSiswa.newDesc = new_desc;
    },
    async download2() {
      this.$q.loading.show();
      const doc = new jsPDF("p", "mm", "a4");

      let data_cover = await html2canvas(this.$refs.cover, { scale: 2 });
      const cover = data_cover.toDataURL("image/png");
      let data_alamat = await html2canvas(this.$refs.alamat, { scale: 2 });
      const alamat = data_alamat.toDataURL("image/png");

      doc.addImage(cover, "JPEG", 0, 0, 210, 297, "aliasx", "MEDIUM");
      doc.addPage();
      doc.addImage(alamat, "JPEG", 0, 0, 210, 297, "aliasy", "MEDIUM");
      doc.addPage();

      let data_header = await html2canvas(this.$refs.header, { scale: 2 });
      const header = data_header.toDataURL("image/png");
      let data_ttd = await html2canvas(this.$refs.ttd, { scale: 2 });
      const ttd = data_ttd.toDataURL("image/png");

      //ukuran data pada halaman
      var imgWidth = 210;
      var pageHeight = 215;

      //sikap
      let result = await html2canvas(this.$refs.sikap, { scale: 2 });
      var imgHeight = (result.height * imgWidth) / result.width;
      var heightLeft = imgHeight;

      var gambar = result.toDataURL("image/png");

      var position = 60;
      doc.addImage(header, "JPEG", 0, 0, 210, 50, "alias1", "MEDIUM");
      doc.addImage(
        gambar,
        "JPEG",
        0,
        position,
        imgWidth,
        imgHeight + 15,
        "sikap",
        "MEDIUM"
      );
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(header, "JPEG", 0, 0, 210, 50, "alias1", "MEDIUM");
        doc.addImage(
          gambar,
          "PNG",
          0,
          position,
          imgWidth,
          imgHeight + 15,
          "sikap",
          "MEDIUM"
        );
        heightLeft -= pageHeight;
      }
      doc.addImage(ttd, "JPEG", 0, 260, 210, 30, "alias2", "MEDIUM");

      //nilai
      doc.addPage();
      result = await html2canvas(this.$refs.nilai, { scale: 2 });
      imgHeight = (result.height * imgWidth) / result.width;
      heightLeft = imgHeight;

      gambar = result.toDataURL("image/png");

      position = 60;
      doc.addImage(header, "JPEG", 0, 0, 210, 50, "alias1", "MEDIUM");
      doc.addImage(
        gambar,
        "JPEG",
        0,
        position,
        imgWidth,
        imgHeight + 15,
        "nilai",
        "MEDIUM"
      );
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(header, "JPEG", 0, 0, 210, 50, "alias1", "MEDIUM");
        doc.addImage(
          gambar,
          "PNG",
          0,
          position,
          imgWidth,
          imgHeight + 15,
          "nilai",
          "MEDIUM"
        );
        heightLeft -= pageHeight;
      }
      doc.addImage(ttd, "JPEG", 0, 260, 210, 30, "alias2", "MEDIUM");

      doc.save("a4.pdf");
      this.$q.loading.hide();
    },
    async download() {
      this.$q.loading.show();
      const doc = new jsPDF("p", "mm", "a4");
      const gambar = [];
      let page = await html2canvas(this.$refs.cover, { scale: 2 });
      gambar.push(page.toDataURL("image/png"));
      page = await html2canvas(this.$refs.alamat, { scale: 2 });
      gambar.push(page.toDataURL("image/png"));
      page = await html2canvas(this.$refs.sikap, { scale: 2 });
      gambar.push(page.toDataURL("image/png"));

      for (let i in this.$refs.nilai) {
        page = await html2canvas(this.$refs.nilai[i], { scale: 2 });
        gambar.push(page.toDataURL("image/png"));
      }

      for (let i in this.$refs.deskripsi) {
        page = await html2canvas(this.$refs.deskripsi[i], { scale: 2 });
        gambar.push(page.toDataURL("image/png"));
      }

      page = await html2canvas(this.$refs.lainya, { scale: 2 });
      gambar.push(page.toDataURL("image/png"));

      for (let i in gambar) {
        doc.addImage(gambar[i], "JPEG", 0, 0, 210, 297, `alias${i}`, "MEDIUM");
        if (i < gambar.length - 1) {
          doc.addPage();
        }
      }
      doc.save(
        `Raport ${this.selSiswa.label} Periode ${this.selTahun.label}.pdf`
      );
      this.$q.loading.hide();
    },
  },
};
</script>

<style lang="scss">
.text-predikat {
  font-size: 3em !important;
}
.text-lebih-kecil {
  font-size: 0.85em !important;
}
.text-kecil {
  font-size: 1em !important;
}
.text-besar {
  font-size: 1.18em !important;
}
.paper {
  width: 210mm;
  height: 297mm;
  padding: 1cm 1.9cm 1cm 1.9cm;
}
</style>
